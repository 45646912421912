<template>
  <div>
    <a-form layout="inline" :form="form1" @submit="handleSearch">
      <a-form-item>
        <a-input
          v-decorator="['key']"
          placeholder="请输入账号名称"
          autocomplete="off"
        >
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit"> 搜索 </a-button>
      </a-form-item>
      <a-form-item>
        <a-button type="danger" @click="handleClear" html-type="button">
          清空
        </a-button>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="destroyMultiple"> 批量删除 </a-button>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="showModal('new')">
          新增后台用户账号
        </a-button>
      </a-form-item>
    </a-form>
    <a-table
      :columns="columns"
      :dataSource="data"
      :pagination="false"
      :loading="loading"
      :row-selection="rowSelection"
    >
      <template slot="name" slot-scope="text, record">
        <a @click="getDetail(record)">{{ text }}</a>
      </template>
      <template slot="is_activity" slot-scope="text, record">
        <a-switch :default-checked="text" @change="onChange(record)" />
      </template>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="handleById(record, 'edit')">编辑</a>
        <a-divider type="vertical" />
        <a href="javascript:;" class="delete" @click="handleDelete(record)"
          >删除</a
        >
      </span>
    </a-table>
    <Pagination
      :current="current"
      :total="total"
      @handleChange="handleChange"
      v-show="total > 20"
    />
    <a-modal
      :title="title"
      v-model="visible"
      @ok="handleOk"
      @cancel="handleCancel"
      okText="确认"
      cancelText="取消"
      width="450px"
    >
      <a-form
        :form="form2"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-form-item label="用户账号">
          <a-input
            v-decorator="[
              'admin_name',
              { rules: [{ required: true, message: '请输入用户账号!' }] },
            ]"
          />
        </a-form-item>
        <a-form-item v-if="title == '新增'" label="账号密码">
          <a-input
            v-decorator="[
              'password',
              { rules: [{ required: true, message: '请输入账号密码!' }] },
            ]"
          />
        </a-form-item>
        <a-form-item v-else label="账号密码">
          <a-input v-decorator="['password', { rules: [] }]" />
        </a-form-item>
        <a-form-item label="联系人">
          <a-input v-decorator="['contact', { rules: [] }]" />
        </a-form-item>
        <a-form-item label="联系方式">
          <a-input v-decorator="['phone', { rules: [] }]" />
        </a-form-item>
        <a-form-item label="邮箱">
          <a-input
            v-decorator="[
              'email',
              { rules: [{ required: true, message: '请绑定邮箱!' }] },
            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { UsersModel } from "../../models/user";
import Pagination from "../../components/Pagination";
import formatTime from "../../utils/formatTime";
const usersModel = new UsersModel();
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");
let all_id = [];
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    all_id = [];
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
    selectedRows.forEach((item) => {
      all_id.push(item.id);
    });
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};

const columns = [
  {
    title: "用户ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "用户账号",
    dataIndex: "admin_name",
    key: "admin_name",
  },
  {
    title: "联系人",
    dataIndex: "contact",
    key: "contact",
  },
  {
    title: "联系方式",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "邮箱",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "创建日期",
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    title: "有效性",
    dataIndex: "is_activity",
    key: "is_activity",
    scopedSlots: { customRender: "is_activity" },
  },
  {
    title: "操作",
    dataIndex: "",
    key: "action",
    scopedSlots: {
      customRender: "action",
    },
  },
];
export default {
  data() {
    return {
      form1: this.$form.createForm(this, {
        name: "horizontal_login",
      }),
      data: [],
      columns,
      visible: false,
      flag: "",
      loading: false,
      total: 0,
      id: 0,
      rowSelection,
      title: "",
      current: 1,
      // locale,
      form2: this.$form.createForm(this, { name: "coordinated" }),
    };
  },

  created() {
    this.queryUsersList();
  },

  methods: {
    destroyMultiple() {
      if (!all_id.length) {
        this.$confirm({
          content: "请选择一项!",
          onOk: () => {},
          onCancel() {},
        });
      } else {
        this.$confirm({
          content: "确定要批量删除吗？",
          onOk: () => {
            usersModel
              .destroyMultiple({ all_id: JSON.stringify(all_id) })
              .then((res) => {
                if (res.code == 0) {
                  this.$notification.open({
                    message: "提示",
                    description: res.message,
                    duration: 2,
                  });
                  this.queryUsersList();
                }
              });
          },
          onCancel() {},
        });
      }
    },

    handleDelete(record) {
      const confirmText = ["确定删除当前后台用户账号吗？", "删除后将不可恢复"];
      const newDatas = [];
      const h = this.$createElement;
      for (const i in confirmText) {
        newDatas.push(h("p", null, confirmText[i]));
      }
      this.$confirm({
        content: h("div", null, newDatas),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          usersModel.destroy(record.id).then((res) => {
            if (res.code == 0) {
              this.$notification.open({
                message: "提示",
                description: res.message,
                duration: 2,
              });
              this.queryUsersList();
            }
          });
        },
        onCancel() {},
      });
    },

    onChange(record) {
      let url = "board/admin_manages/" + record.id;
      usersModel
        .update({ is_activity: !record.is_activity }, url)
        .then((res) => {
          if (res.code == 0) {
            this.$notification.open({
              message: "提示",
              description: res.message,
              duration: 2,
            });
            this.queryUsersList();
          }
        });
    },

    handleOk(e) {
      e.preventDefault();
      this.form2.validateFields((err, values) => {
        if (!err) {
          if (this.flag == "new") {
            usersModel.create(values).then((res) => {
              if (res.code == 0) {
                this.$notification.open({
                  message: "提示",
                  description: res.message,
                  duration: 2,
                });
                this.visible = false;
                this.queryUsersList();
              }
            });
          } else {
            let url = "board/admin_manages/" + this.id;
            usersModel.update(values, url).then((res) => {
              if (res.code == 0) {
                this.$notification.open({
                  message: "提示",
                  description: res.message,
                  duration: 2,
                });
                this.visible = false;
                window.localStorage.clear();
                this.$router.push({
                  path: "/login",
                  query: {
                    redirect: this.$route.path,
                  },
                });
              }
            });
          }
          this.form2.resetFields();
        }
      });
    },

    handleSearch(e) {
      e.preventDefault();
      this.form1.validateFields((err, values) => {
        if (!err) {
          let key = values.key ? values.key.trim() : "";
          this.queryUsersList(key, 1);
        }
      });
    },

    handleClear() {
      this.form1.resetFields();
      this.queryUsersList();
    },

    handleCancel() {
      this.form2.resetFields();
    },

    handleById(record, flag) {
      this.visible = true;
      this.title = "编辑";
      this.flag = flag;
      this.id = record.id;
      this.$nextTick(() => {
        this.form2.setFieldsValue({
          admin_name: record.admin_name,
          contact: record.contact,
          phone: record.phone,
          email: record.email,
        });
      });
    },

    showModal(flag) {
      this.flag = flag;
      this.visible = true;
      this.title = "新增";
    },

    queryUsersList(key, page) {
      this.loading = true;
      usersModel.index(key, page).then((res) => {
        if (res.code == 0) {
          this.current = page;
          res.result.map((item, index) => {
            item.key = index;
            item.created_at = formatTime(item.created_at);
            return item;
          });
          this.data = res.result;
          this.loading = false;
          this.total = res.total;
        }
      });
    },
    handleChange(page) {
      this.current = page;
      this.form1.validateFields((err, values) => {
        if (!err) {
          this.queryUsersList(values.key, page);
        }
      });
    },
  },

  components: {
    Pagination,
  },
};
</script>

<style lang="scss" scoped>
.sm-select {
  width: 170px;
}
</style>